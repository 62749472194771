import { Col, Row } from 'react-bootstrap';
import { HttpMethod } from '../../constants';

const getHttpMethodColor = (httpMethod) => {
  if (httpMethod === HttpMethod.GET) { return 'primary'; }
  if (httpMethod === HttpMethod.POST) { return 'green'; }
  if (httpMethod === HttpMethod.DELETE) { return 'danger'; }
  return 'black';
};

const getDataTypeColor = (data) => {
  if (data === 'true' || data === 'false') { return 'primary'; }
  if (data === 'null' || data === 'defined') { return 'grey'; }
  if (typeof data === 'string') { return 'green'; }
  if (typeof data === 'number' || typeof data === 'bigint') { return 'danger'; }
  return 'black';
};

const displayJson = (jsonRaw, level = 0) => {
  const startBodyClass = `col-12 ps-${8 * (level + 1)}`;
  const startNewBodyClass = `col-12 ps-${8 * (level + 2)}`;

  const limitTextLength = (text, length = 200) => (
    text.length <= length ? text : `${text.substring(0, length)}...`
  );

  const addQuotes = (text) => {
    let reformattedText = text;
    if (text === null) {
      reformattedText = 'null';
    } else if (text === undefined) {
      reformattedText = 'null';
    } else if (typeof text === 'string') {
      if (typeof text === 'string') {
        reformattedText = limitTextLength(text);
      }
      reformattedText = `"${reformattedText}"`;
    } else if (text === true) {
      reformattedText = 'true';
    } else if (text === false) {
      reformattedText = 'false';
    }

    return <span className={`text-${getDataTypeColor(reformattedText)}`}>{reformattedText}</span>;
  };

  const jsonString = jsonRaw ? Object.entries(jsonRaw).map(([key, value]) => {
    const start = [];
    const startText = `"${key}"`;
    if (value) {
      if (Array.isArray(value) && value.length === 0) {
        start.push((
          <span className={startBodyClass}>
            {startText}
            <span className="text-grey">: []</span>
          </span>
        ));
        return start;
      }
      if (typeof value === 'object' && Object.entries(value).length === 0) {
        start.push((
          <span className={startBodyClass}>
            {startText}
            <span className="text-grey">{': {}'}</span>
          </span>
        ));
        return start;
      }
    }

    // is array of objects
    if (Array.isArray(value) && typeof value[0] === 'object') {
      const y = ': [';
      const z = '],';
      start.push((
        <span className={startBodyClass}>
          {startText}
          <span className="text-grey">{y}</span>
        </span>
      ));
      const valueResult = value.map(x => {
        const result = displayJson(x, level + 2);
        return [
          <span className={`${startNewBodyClass} text-grey`}>{'{'}</span>,
          result,
          <span className={`${startNewBodyClass} col-12 text-grey`}>
            {'},'}
          </span>,
        ];
      });
      start.push(valueResult);

      start.push(<span className={`${startBodyClass} text-grey`}>{z}</span>);
    } else if (Array.isArray(value)) {
      const y = ': [';
      const z = '],';
      const count = value.length - 1;
      const text = value.map((x, idx) => {
        const result = addQuotes(x);
        return [
          result,
          idx < count ? <span className="text-grey">, </span> : null,
        ];
      });
      start.push((
        <span className={startBodyClass}>
          {startText}
          <span className="text-grey">{y}</span>
          <span> </span>
          {text}
          <span> </span>
          <span className="text-grey">{z}</span>
        </span>
      ));
    } else if (value && typeof value === 'object') {
      // start.push(<span className={startNewBodyClass}>{'{'}</span>)
      start.push((
        <span className={startBodyClass}>
          {startText}
          <span className="text-grey">{': {'}</span>
        </span>
      ));

      start.push(displayJson(value, level + 2));
      start.push(<span className={`${startBodyClass} text-grey`}>{'},'}</span>);
    } else {
      start.push((
        <span className={startBodyClass}>
          {startText}
          <span>: </span>
          {addQuotes(value)}
          ,
        </span>
      ));
    }

    return start;
  }) : null;

  return (
    <div className="d-flex flex-wrap">
      {jsonString}
    </div>
  );
};

const getRequest = (type, endpoint, body = '') => (
  <Row>
    <span className="col-12">
      {`curl -X '${type}' \\`}
    </span>
    <span className="col-12">{`'${endpoint}' \\`}</span>
    <span className="col-12">{'-H \'accept: application/json\' \\'}</span>
    <span className="col-12">{'-H \'x-api-key: {\'<your-api-key>\'}\''}</span>
    {body && (
      <>
        <Col className="col-12">{'-d \'{'}</Col>
        {displayJson(body)}
        <Col className="col-12">{'}\''}</Col>
      </>
    )}
  </Row>
);

export {
  displayJson,
  getHttpMethodColor,
  getDataTypeColor,
  getRequest,
};
