import { batch, effect } from '@preact/signals-core';
import { firestore } from './firebase';
import {
  errorsSignal,
  isLoadingSignal,
  isLoggedInSignal,
  updateSignal,
  userDataSignal,
} from '../../libs/signals';

const isDeepCompareEqual = (itemA, itemB) => JSON.stringify(itemA) === JSON.stringify(itemB);

function handleSnapShotChange(signalName) {
  return (snapshot) => {
    if (signalName !== 'userData') throw new Error('signalName must be userData');

    const signal = userDataSignal.value.userData;
    if (!snapshot?.exists || isDeepCompareEqual(snapshot.get(signalName), signal)) return;

    updateSignal(snapshot.data(), signalName);
  };
}

function handleSnapshotError(FSError) {
  const filtered = errorsSignal.value.errors.error.filter(err => err.code !== FSError.code);
  if (filtered.length === errorsSignal.value.errors.error.length) return;
  updateSignal({ errors: { error: filtered } }, 'errors');
}

export const subscribe = async (user) => {
  isLoadingSignal.value = true;
  const fbUsersRef = firestore.collection('users').doc(user?.email);
  const userSnap = await fbUsersRef.get();

  // Set initial values
  batch(async () => {
    const fetchedUserData = userSnap.data();
    if (userSnap.exists) {
      userDataSignal.value = fetchedUserData;
    }

    isLoggedInSignal.value = true;
  });

  // Set auto-update callbacks (client to fs and fs to client)
  const dispatchEffect = effect(() => {
    fbUsersRef.set(userDataSignal.value, { merge: true });
  });
  // Waits for Firestore changes and then updates the Signal for UserData
  const detatchSnapshot = fbUsersRef.onSnapshot(handleSnapShotChange('userData'), handleSnapshotError);
  isLoadingSignal.value = false;
  return () => {
    detatchSnapshot();
    dispatchEffect();
  };
};

export default { subscribe };
