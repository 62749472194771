import firebase from 'firebase/compat/app';
import { getConfig } from '../../../config/config';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

export const firebaseConfig = getConfig('REACT_APP_FIREBASE_CONFIG');

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'consent',
  tenant: 'e240f7e6-3beb-43e1-9f99-edfd6f3431e3',
});
const analytics = firebase.analytics();
const now = firebase.firestore.Timestamp.now();
const fbKey = `firebase:authUser:${getConfig('REACT_APP_FIREBASE_PUB_KEY')}:[DEFAULT]`;

export {
  auth,
  firestore,
  googleProvider,
  microsoftProvider,
  now,
  fbKey,
  analytics,
};
