/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Form, Row, Button, Dropdown } from 'react-bootstrap';
import api from '../../../api/api';
import { clientDataSignal, isLoadingSignal, isLoggedInSignal, userDataSignal } from '../../libs/signals';
import { useAlert } from '../../providers/AlertProvider/AlertProvider';
import { auth, firestore } from '../../providers/FirebaseProvider/firebase';
import convertToDateTime from '../../utils/convertToDateTime';
import makeid from '../../utils/makeId';
import AddUserModal from '../Users/components/AddUserModal';
import AddNewClientModal from './components/AddNewClientModal';
import DeleteClientModal from './components/DeleteClientModal';
import EditClientModal from './components/EditClientModal';

const Clients = () => {
  const setAlert = useAlert();
  const [clients, setClients] = useState([]);
  const [isNewClientOpen, setIsNewClientOpen] = useState(false);
  const [isNewUserOpen, setIsNewUserOpen] = useState(false);
  const [newClientData, setNewClientData] = useState({
    clientData: {
      clientName: '',
      email: '',
      fullName: '',
    },
  });
  const [newMember, setNewMember] = useState({
    userData: {
      name: '',
      email: '',
      userType: '',
    },
  });
  const [inviteClientId, setInviteClientId] = useState(null);
  const [isEditClientOpen, setIsEditClientOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientId, setClientId] = useState('');
  const [isDeleteClientOpen, setIsDeleteClientOpen] = useState(false);

  const fetchClientData = useCallback(async () => {
    const snapshot = await firestore.collection('clients').get();
    if (snapshot) {
      const clientArray = [];
      const res = snapshot.docs.map((doc) => doc.data());
      res.forEach((obj) => {
        const apiKeys = obj.clientData.apiKeys ? obj.clientData.apiKeys.map((key) => key.id) : [];
        const members = obj.clientData.members ? obj.clientData.members.map((key) => key.userObj.id) : [];
        const clientObj = {
          id: obj.clientData.id || 'N/A',
          name: obj.clientData.name || 'N/A',
          members,
          apiKeys,
        };
        clientArray.push(clientObj);
      });
      setClients(clientArray);
    }
  });
  useEffect(() => {
    fetchClientData();
  }, []);

  // New Client func
  const handleNewClientClicked = () => {
    setIsNewClientOpen(!isNewClientOpen);
  };
  const onNewClientChange = (e) => {
    setNewClientData({
      clientData: {
        ...newClientData?.clientData,
        [e.target.name]: e.target.value,
      },
    });
  };
  const createNewClient = async () => {
    try {
      isLoadingSignal.value = true;
      firestore.collection('users').doc(newClientData.clientData.email).get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          setAlert({
            message: 'This User already Exists',
            variant: 'alert',
          });
          isLoadingSignal.value = false;
        } else {
          const newClientId = makeid(20);
          firestore.collection('users').doc(newClientData.clientData.email).set({
            userData: {
              name: newClientData.clientData.fullName,
              email: newClientData.clientData.email,
              userType: 'Administrator',
              isActive: false,
              clientId: newClientId,
              uid: null,
              lastSignInTime: null,
              creationTime: null,
            },
          });
          firestore.collection('clients').doc(newClientId).set({
            clientData: {
              apiKeys: [],
              id: newClientId,
              members: [],
              name: newClientData.clientData.clientName,
            },
          });
          firestore.collection('stats').doc(newClientId).set({
            stats: [],
          });
          const firstDocId = makeid(10);
          firestore.collection('stats').doc(newClientId).collection('logs').doc(firstDocId)
            .set({
              id: firstDocId,
              data: 'First Log',
            });
          const fbClientRef = firestore.collection('clients').doc(newClientId);
          const clientSnap = await fbClientRef.get();
          const clientObj = clientSnap.data();
          const newMemberArray = [];
          newMemberArray.push({
            userObj: firestore.collection('users').doc(newClientData.clientData.email),
          });
          firestore.collection('clients').doc(newClientId).update({
            clientData: {
              ...clientObj?.clientData,
              members: newMemberArray,
            },
          });

          const postBody = {
            email: newClientData.clientData.email,
            name: newClientData.clientData.fullName,
          };
          api.post({ path: '/account/invite', body: postBody });
          handleNewClientClicked();
          setNewClientData({
            clientData: {
              clientName: '',
              email: '',
              fullName: '',
            },
          });
          await fetchClientData();
          setAlert({
            message: 'Invite successfully sent to the user',
            variant: 'success',
          });
        }
      });
    } catch (error) {
      console.error(error);
      isLoadingSignal.value = false;
    } finally {
      isLoadingSignal.value = false;
    }
  };

  // check and reinvite owner
  const resendInviteToOwner = async (value) => {
    if (value[0]) {
      firestore.collection('users').doc(value[0]).get().then(result => {
        const user = result.data();
        if (user.userData.creationTime === null) {
          try {
            const postBody = {
              email: user.userData.email,
              name: user.userData.name,
            };
            api.post({ path: '/account/invite', body: postBody });
            setAlert({
              message: 'Invite Sent!',
              variant: 'success',
            });
          } catch (error) {
            console.error(error);
            setAlert({
              message: 'Invite Failed!',
              variant: 'danger',
            });
          }
        } else {
          setAlert({
            message: 'Owner has already signed up',
            variant: 'warning',
          });
        }
      });
    }
  };

  // invite new member
  const handleNewUserClicked = (id) => {
    setIsNewUserOpen(!isNewUserOpen);
    setInviteClientId(id);
  };
  const onNewMemberChange = (e) => {
    setNewMember({
      userData: {
        ...newMember?.userData,
        [e.target.name]: e.target.value,
      },
    });
  };
  const onNewMemberSave = async () => {
    try {
      isLoadingSignal.value = true;
      firestore.collection('users').doc(newMember?.userData?.email).get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          // eslint-disable-next-line no-alert
          alert('USER ALREADY EXISTS');
          isLoadingSignal.value = false;
        } else {
          firestore.collection('users').doc(newMember?.userData?.email).set({
            userData: {
              ...newMember?.userData,
              isActive: false,
              clientId: inviteClientId,
              uid: null,
              lastSignInTime: null,
              creationTime: null,
            },
          });
          const fbClientRef = firestore.collection('clients').doc(inviteClientId);
          const clientSnap = await fbClientRef.get();
          const clientObj = clientSnap.data();
          const membersArray = clientObj?.clientData?.members;
          membersArray.push({
            userObj: firestore.collection('users').doc(newMember?.userData?.email),
          });

          firestore.collection('clients').doc(inviteClientId).update({
            clientData: {
              ...clientObj?.clientData,
              name: clientObj?.clientData?.name,
              members: membersArray,
            },
          });
          const postBody = {
            email: newMember?.userData?.email,
            name: newMember?.userData?.name,
          };
          api.post({ path: '/account/invite', body: postBody });
          handleNewUserClicked();
          setNewMember({
            userData: {
              name: '',
              email: '',
              userType: '',
            },
          });
          setInviteClientId(null);
          await fetchClientData();
          setAlert({
            message: 'Invite successfully sent to the user',
            variant: 'success',
          });
        }
      });
    } catch (error) {
      console.error(error);
      isLoadingSignal.value = false;
    } finally {
      isLoadingSignal.value = false;
    }
  };

  // Edit client func
  const handleEditClicked = (clientObj) => {
    setIsEditClientOpen(!isEditClientOpen);
    setClientName(clientObj?.name || '');
    setClientId(clientObj?.id || '');
  };
  const onClientNameChange = (e) => {
    setClientName(e.target.value);
  };
  const handleSelectedClientSave = async () => {
    const fbClientRef = firestore.collection('clients').doc(clientId);
    const clientSnap = await fbClientRef.get();
    const clientObj = clientSnap.data();
    await firestore.collection('clients').doc(clientId).update({
      clientData: {
        ...clientObj?.clientData,
        name: clientName,
      },
    });
    handleEditClicked(null);
    await fetchClientData();
    setAlert({
      message: 'Client was successfully editted',
      variant: 'success',
    });
  };

  // Delete client Functionality
  const handleDeleteClicked = (value) => {
    setIsDeleteClientOpen(!isDeleteClientOpen);
    setClientName(value?.name || '');
    setClientId(value?.id || '');
  };
  const handleDeleteSubmitted = async () => {
    const fbClientRef = firestore.collection('clients').doc(clientId);
    const clientSnap = await fbClientRef.get();
    const clientObj = clientSnap.data();
    if (clientObj.clientData?.apiKeys) {
      clientObj.clientData?.apiKeys.forEach((key) => {
        const apiKeyId = key.id;
        firestore.collection('apiKeys').doc(apiKeyId).delete();
      });
    }
    if (clientObj.clientData?.members) {
      clientObj.clientData?.members.forEach(async (key) => {
        const userId = key.userObj.id;
        firestore.collection('users').doc(userId).delete();
      });
    }
    fbClientRef.delete();
    firestore.collection('stats').doc(clientId).delete();
    handleDeleteClicked(null);
    await fetchClientData();
    setAlert({
      message: 'Client was successfully deleted',
      variant: 'success',
    });
  };

  return (
    <Container fluid className="px-48">
      <h1 className="mt-32 fw-700">
        Clients
      </h1>
      <Button varient="priamry" type="button" onClick={() => handleNewClientClicked()}>Create New Client</Button>
      <Row className="mt-8 mb-16">
        <Col sm={3} className="fw-700">
          Name/ID
        </Col>
        <Col sm={4} className="fw-700">
          Users
        </Col>
        <Col sm={3} className="fw-700">
          API Keys
        </Col>
        <Col sm={2} className="fw-700">
          Invite/Edit/Delete
        </Col>
      </Row>
      {clients && clients?.length && clients?.map((client) => (
        <div className="border-bottom" key={client.name}>
          <Row className="mt-8">
            <Col sm={3} className="fw-700">
              {client.name}
              <br />
              {client.id}

            </Col>
            <Col sm={4}>
              {client.members && client.members.map((user) => (
                <Row key={user} className="m-0">{user}</Row>
              ))}
            </Col>
            <Col sm={3}>
              {client.apiKeys && client.apiKeys.map((key) => (
                <Row key={key} className="m-0">{key}</Row>
              ))}
            </Col>
            <Col sm={1} className="d-flex justify-contnet-end">
              <Dropdown
                align="end"
              >
                <Dropdown.Toggle
                  variant="outline-none"
                  id="dropdown-basic"
                  className="ms-auto p-0 mx-auto w-100"
                >
                  ...
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item
                    onClick={() => resendInviteToOwner(client.members)}
                  >
                    RESEND INVITE TO OWNER
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleNewUserClicked(client.id)}
                  >
                    INVITE
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleEditClicked(client)}
                  >
                    EDIT
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleDeleteClicked(client)}
                  >
                    DELETE
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </div>
      ))}
      <AddNewClientModal
        show={isNewClientOpen}
        handleClose={handleNewClientClicked}
        newClient={newClientData}
        onNewClientChange={onNewClientChange}
        createNewClient={createNewClient}
      />
      <AddUserModal
        show={isNewUserOpen}
        handleClose={handleNewUserClicked}
        newMember={newMember}
        onNewMemberChange={onNewMemberChange}
        onNewMemberSave={onNewMemberSave}
      />
      <EditClientModal
        show={isEditClientOpen}
        handleClose={handleEditClicked}
        clientName={clientName}
        onClientNameChange={onClientNameChange}
        handleSelectedClientSave={handleSelectedClientSave}
      />
      <DeleteClientModal
        show={isDeleteClientOpen}
        handleClose={handleDeleteClicked}
        selectedClient={clientName}
        handleDeleteSubmitted={handleDeleteSubmitted}
      />
    </Container>
  );
};

export default Clients;
