import RequestExample from '../../Components/RequestExample';
import Parameters from '../../Components/Parameters';
import Section from '../../../Components/Section';
import { APIUrls } from '../../../../../../../../constants';
import { displayJson, getRequest } from '../../../../../../../utils/apiDocHelper';

const RequestSection = ({
  endpoint,
  example,
}) => {
  const sectionA = (
    <>
      <div className="pb-24">
        <h3>{endpoint.title}</h3>
        <p>{endpoint.description}</p>
      </div>
      <Parameters
        queryParameters={endpoint.queryParameters}
        bodyParameters={endpoint.bodyParameters}
        returnContent={endpoint.returnContent}
      />
    </>
  );

  const endpointExample = `${APIUrls.BASE_URL_V2}${endpoint.example}`;
  const requestTitle = `${endpoint.method} ${endpoint.endpoint}`;
  const requestContent = getRequest(endpoint.method, endpointExample, endpoint.examplePostBody);
  const sectionB = (
    <RequestExample
      requestTitle={requestTitle}
      requestContent={requestContent}
      responseContent={displayJson(example)}
    />
  );
  return (
    <Section
      sectionA={sectionA}
      sectionB={sectionB}
    />
  );
};

export default RequestSection;
