import { HttpMethod } from '../../../../../../../constants';
import { attributes as OrganizationObjectAttributes } from './OrganizationObject';

const version = 'v1';
const baseUrl = 'vendors';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'vendor_object',
  },
  get: {
    id: 'vendor_retrieval',
  },
  search: {
    id: 'vendor_search',
  },
};

const model = {
  header: 'Vendors',
  title: 'The Vendor Object',
  object: endpointIds.object.id,
  link: `/docs/api/${version}#${endpointIds.object.id}`,
};

const attributes = {
  id: { type: 'string', description: 'The vendor\'s unique identifier.' },
  agencyExpertiseDescription: { type: 'string', description: 'Description of vendor\'s agency expertise.' },
  baseHourlyRate: { type: 'int', description: 'The vendor hourly rate for their services.' },
  bio: { type: 'string', description: 'The vendor\'s bio.' },
  city: { type: 'string', description: 'The city the vendor is located in.' },
  contactEmail: { type: 'string', description: 'The vendor\'s email' },
  currentlyAvailable: { type: 'boolean', description: 'True if vendor is available for service. False if otherwise.' },
  foundationalExpertiseDescription: { type: 'string', description: 'Description of vendor\'s foundational expertise.' },
  gwAgencyTags: { type: 'array of strings', description: 'The list of agencies the vendor is associated with.' },
  gwFoundationTags: { type: 'array of strings', description: 'The list of foundations the vendor is associated with.' },
  gwGrantTypeTags: { type: 'array of strings', description: 'The list of grant types the vendor is associated with. The possible types are: foundation_Grants, government_Grants.' },
  gwProgramTags: { type: 'array of strings', description: 'The list of programs the vendor is associated with.' },
  logoUrl: { type: 'string', description: 'The url of the vendor\'s logo.' },
  mentorProgram: { type: 'string', description: 'Indicates if the user is participating in the mentor program. Possible values if participating are mentor and mentee.' },
  name: { type: 'string', description: 'The vendor\'s name.' },
  organization: {
    type: 'object',
    description: 'The vendor\'s organization information',
    props: Object.keys(OrganizationObjectAttributes),
    ...OrganizationObjectAttributes,
  },
  organizationId: { type: 'int', description: 'The vendor\'s organizaation\'s unique identifier' },
  opengrantsTermsConditions: { type: 'string', description: 'opengrantsTermsConditions.' },
  opengrantsVerified: { type: 'boolean', description: 'True if vendor has been verified by OpenGrants. False if otherwise' },
  programExpertiseDescription: { type: 'string', description: 'Description of vendor\'s program expertise.' },
  recommendations: { type: 'string', description: 'A text of client recommendations' },
  references: { type: 'string', description: 'The vendor\'s references.' },
  referral: { type: 'string', description: 'A text describing who recommended the vendor.' },
  serviceProviderProducts: {
    type: 'array of objects',
    description: 'List of services that the vendor is offering for a flat rate.',
    props: ['scope', 'descriptionProp', 'cost'],
    scope: {
      type: 'string',
      description: 'The scope of the product service.',
    },
    descriptionProp: {
      type: 'string',
      description: 'The description of the product service.',
    },
    cost: {
      type: 'int',
      description: 'The cost of the product service.',
    },
  },
  spFocusTags: { type: 'array of strings', description: 'The list of industry focus the vendor is associated with..' },
  spRoleTags: { type: 'array of strings', description: 'The list of vendor roles. The possible roles are: grant_Writer, startup_Advisor, technical_Consultant, accounting_Professional, attorney, generalist, and non_Profit_Advisor.' },
  spServicesTags: { type: 'array of strings', description: 'The list of industry services the vendor is associated with.' },
  state: { type: 'string', description: 'The state the vendor is located in.' },
};

const endpoints = {
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}/{id}`,
    example: `/${baseUrl}/123735043`,
    title: 'Retrieve a Vendor',
    description: 'Retrieves a vendor by the vendor id',
    returnContent: 'Returns a Vendor object if the call succeeded',
  },
  search: {
    id: endpointIds.search.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.search.id}`,
    endpoint: `/${baseUrl}`,
    example: '/vendors?query=grants%20water&page=',
    title: 'Search Vendors',
    description: 'Searches vendor',
    returnContent: 'Returns a Pagination object with the data property containing an array of Vendor objects.',
    queryParameters: {
      query: { type: 'optional', description: 'Search vendor name and bio for any mention of the filter text.' },
      page: { type: 'optional', description: 'Retrieve the vendors from the specifed page.' },
    },
  },
};

export {
  model,
  attributes,
  endpoints,
};
