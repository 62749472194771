const model = {
  header: 'Pagination',
  title: 'The Pagination Object',
  object: 'pagination_object',
  link: '/docs/api/v2#pagination_object',
};

const attributes = {
  data: { type: 'object', description: 'An object containing multiple array of objects the search criteria.' },
  totalResults: { type: 'int', description: 'The total number of objects found.' },
  page: { type: 'int', description: 'The current pagination number.' },
  totalPages: { type: 'int', description: 'The total number of pages to query to get all results.' },
  totalResultsInPage: { type: 'int', description: 'The number of objects retrieved for the current page.' },
};

export {
  model,
  attributes,
};
