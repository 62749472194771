/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedInSignal } from '../../../libs/signals';
import AppContainer from '../../AppContainer';

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (!(isLoggedInSignal?.value || window.localStorage.getItem('loggedIn') === 'true')) return <Redirect to="/login" />;
  return (
    <Route
      {...rest}
      render={(props) => (
        <AppContainer isLoggedIn>
          <Component {...props} />
        </AppContainer>
      )}
    />
  );
};

export default PrivateRoute;
