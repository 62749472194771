import { HttpMethod } from '../../../../../../../constants';

const version = 'v2';
const baseUrl = 'grants';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'grant_object',
  },
  get: {
    id: 'grant_retrieval',
  },
  getAll: {
    id: 'grant_list',
  },
  search: {
    id: 'grant_search',
  },
};

const model = {
  header: 'Grants',
  title: 'The Grant Object',
  object: endpointIds.object.id,
  link: `/docs/api/${version}#${endpointIds.object.id}`,
};

const attributes = {
  id: { type: 'int', description: 'The grant\'s unique identifier.' },
  agency: { type: 'string', description: 'The agency that issued the grant.' },
  applicationDueDate: { type: 'string', description: 'The date the application is due.' },
  categories: {
    type: 'array of strings',
    description:
      (
        <>
          An array of categories that the grant falls under.
          <span> </span>
          <a href={`/docs/api/${version}#filter_object`}>
            See Filter Options to retrieve all possible options.
          </a>
        </>
      ),
  },
  contactInformation: {
    type: 'object',
    description: 'The contact information for the grant.',
    props: ['descriptionProp', 'emailDescription', 'email', 'name'],
    descriptionProp: { type: 'string', description: 'A short description of the contact.' },
    emailDescription: { type: 'string', description: 'A short description of the contact\'s email.' },
    email: { type: 'string', description: 'The email to contact about the grant.' },
    name: { type: 'string', description: 'The name of the contact.' },
    phoneNumber: { type: 'string', description: 'The contact\'s phone number.' },
  },
  description: { type: 'string', description: 'The grant\'s description.' },
  eligibility: {
    type: 'object',
    description: 'The grant\'s eligible applicants and activities.',
    props: ['activities', 'applicants'],
    activities: {
      type: 'array of strings',
      description:
        (
          <>
            The eligible activities.
            <span> </span>
            <a href={`/docs/api/${version}#filter_object`}>
              See Filter Options to retrieve all possible options.
            </a>
          </>
        ),
    },
    applicants: {
      type: 'array of strings',
      description: 'The eligible applicants',
    },
  },
  estimatedAwardDate: { type: 'string', description: 'The estimated award date.' },
  fundingAmount: { type: 'string', description: 'The max amount of program funding provided to an eligible applicant in the Program Year. A \'0\' or \'null\' indicates that no funding was found or provided.' },
  fundingMethod: { type: 'string', description: 'The grant\'s funding method.' },
  fundingTypes: { type: 'array of strings', description: 'The grant\'s funding type.' },
  howToApply: { type: 'string', description: 'A description of how to apply.' },
  website: { type: 'string', description: 'The link to the grant.' },
  locations: {
    type: 'array of strings',
    description:
      (
        <>
          {'The grant\'s applicable locations. Some possbile values are Federal, International, and states in the united states.'}
          <span> </span>
          <a href={`/docs/api/${version}#filter_object`}>
            See Filter Options to retrieve all possible options.
          </a>
        </>
      ),
  },
  matchRequired: { type: 'boolean', description: 'True if a match is required. False if otherwise.' },
  openDate: { type: 'string', description: 'The date the grant opens.' },
  status: { type: 'string', description: 'The grant\'s status. Possible values are Posted, Forecasted, Closed, and Archived.' },
  title: { type: 'string', description: 'The grant\'s title.' },
  totalProgramFunding: { type: 'string', description: 'The grant\'s total amount of funding.' },
};

const endpoints =
{
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}/{id}`,
    example: `/${baseUrl}/1914979`,
    title: 'Retrieve a Grant',
    description: 'Retrieves a grant by the grant object id',
    returnContent: 'Returns the Grant object if the call succeeded',
  },
  getAll: {
    id: endpointIds.getAll.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.getAll.id}`,
    endpoint: `/${baseUrl}/all`,
    example: `/${baseUrl}/all`,
    title: 'Retrieve All Grants',
    description: 'Retrieve all the grants from the OpenGrants database',
    returnContent: 'Returns an array of Grant objects and a totalResult property containing the total count of the data.',
  },
  search: {
    id: endpointIds.search.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.search.id}`,
    endpoint: `/${baseUrl}`,
    example: `/${baseUrl}?query=Health%20Program&facetFilters=[["U.S. Department of Health and Human Services (HHS)"]]`,
    title: 'Search Grants',
    description: 'Searches and returns an array of grants from the OpenGrants database via pagination',
    returnContent: 'Returns a Pagination object with the data property containing an array of Grant objects.',
    queryParameters: {
      query: { type: 'optional', description: 'Search the grant title and description for any mention of the query text.' },
      page: { type: 'optional', description: 'Retrieve the grants from the specifed page.' },
      filterFacets: {
        type: 'optional',
        description: (
          <>
            Filter grants by attributes
            <br />
            <br />
            Possible facet attributes are agency, locations,
            fundingTypes, status, categories,
            eligibleApplicants, matchRequired, and
            applicationDueDate
            <br />
            <br />
            To filter by text value, set facetFilters=[
            {'<options>'}
            ] where
            {' <options> '}
            is one or a combination of the following:
            <ul>
              <li>&quot;attribute:value&quot; for single filtering</li>
              <li>
                &quot;attribute:value&quot;,&quot;attribute:value&quot;
                for filtering by AND
              </li>
              <li>
                [&quot;attribute:value&quot;,&quot;attribute:value&quot;]
                for filtering by OR
              </li>
            </ul>
            Attribute is one of the possible facet attributes mentioned
            above, and value is the value you would like to filter by.
            Note that the attribute and the value are match-case.
            This means that the attribute and value must match the options
            exactly to get the expected returns.
            <span> </span>
            <a href={`/docs/api/${version}#filter_object`}>
              See Filter Options to retrieve possible options for each of the attribute
            </a>
            <br />
            <br />
            <div className="ps-16">
              Example (filter by single facet):
              <br />
              facetFilters=[&quot;agency:Department of Social Services&quot;]
              <br />
              <br />
              Example (filter by multiple facet AND):
              <br />
              facetFilters=[&quot;agency: Department of Social Services&quot;,
              &quot;type:listings&quot;]
              <br />
              <br />
              Example (filter by multiple facet OR):
              <br />
              facetFilters=[[&quot;agency: Department of Social Services&quot;,
              &quot;type:listings&quot;]]
            </div>
            <br />
            To filter by date, set facetFilters=[
            {'<options>'}
            ]
            where
            {' <options> '}
            can be one or a combination of the above and/or:
            <ul>
              <li>
                &quot;attribute
                {'<numberic-comparison>'}
                value&quot; for single filtering
              </li>
              <li>
                &quot;attribute
                {'<numberic-comparison>'}
                value&quot;,&quot;attribute
                {'<numberic-comparison>'}
                value&quot;
                for filtering by AND
              </li>
              <li>
                [&quot;attribute
                {'<numberic-comparison>'}
                value&quot;,&quot;attribute
                {'<numberic-comparison>'}
                value&quot;]
                for filtering by OR
              </li>
            </ul>
            The attribute must have be a date type, the
            {' <numberic-comparison> can be any one of the following: = , != , > , >= , < , <= '}
            and the value must be UNIX timestamp
            <br />
            <br />
            <div className="ps-16">
              Example (filter by single facet):
              <br />
              facetFilters=[&quot;
              {'applicationDueDate >= 1702512000000'}
              &quot;]
              <br />
              <br />
              Example (filter by multiple facet AND):
              <br />
              facetFilters=[&quot;
              {'applicationDueDate >= 1702512000000'}
              &quot;,
              &quot;
              {'applicationDueDate <= 1706774399999'}
              &quot;]
              <br />
              <br />
              Example (filter by multiple facet OR):
              <br />
              facetFilters=[[&quot;
              {'applicationDueDate <= 1706774399999'}
              &quot;,
              &quot;
              {'applicationDueDate <= 1706774399999'}
              &quot;]]
            </div>
          </>
        ),
      },
    },
  },
};

export {
  model,
  attributes,
  endpoints,
};
