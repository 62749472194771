import { Row } from 'react-bootstrap';
import { BannerTypeColors } from '../../../constants';

const NotificationBanner = ({ type, title, text }) => (
  <Row className="justify-content-center">
    <div className={`bg-${BannerTypeColors[type]} bg-opacity-50 p-24 w-75 rounded`}>
      <span className="fw-bold">
        {title}
        :
      </span>
      <span> </span>
      <span>
        {text}
      </span>
    </div>
  </Row>
);

export default NotificationBanner;
