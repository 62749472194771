import { Button, Form, Modal } from 'react-bootstrap';
import validateEmail from '../../../utils/validateEmail';

const AddUserModal = ({ show, handleClose, newMember, onNewMemberChange, onNewMemberSave }) => (
  <Modal show={show} onHide={() => handleClose()}>
    <Modal.Header closeButton>
      <Modal.Title>New Member</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group className="form-group">
        <Form.Label htmlFor="inputEmail">
          Email
          {!validateEmail(newMember?.userData?.email) && (
            <small className="text-danger fw-700 ps-8">Invalid email</small>
          )}
        </Form.Label>
        <Form.Control
          className="form-control form-control-sm"
          type="text"
          name="email"
          value={newMember?.userData?.email}
          onChange={onNewMemberChange}
        />
        <Form.Label htmlFor="inputFullName" className="mt-16">Full Name</Form.Label>
        <Form.Control
          className="form-control form-control-sm"
          type="text"
          name="name"
          value={newMember?.userData?.name}
          onChange={onNewMemberChange}
        />

        <Form.Label htmlFor="inputUserType" className="mt-16">Role</Form.Label>
        <Form.Select
          className="form-control form-control-sm"
          type="text"
          name="userType"
          value={newMember?.userData?.userType}
          onChange={onNewMemberChange}
        >
          <option>Select</option>
          <option value="Developer">Developer</option>
          <option value="Administrator">Administrator</option>
        </Form.Select>
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="dark-blue"
        onClick={onNewMemberSave}
        disabled={
          !newMember?.userData?.name ||
          !newMember?.userData?.email ||
          !newMember?.userData?.userType ||
          !validateEmail(newMember?.userData?.email)
        }
      >
        Send Invite
      </Button>
    </Modal.Footer>
  </Modal>
);

export default AddUserModal;
