import Response from './Response';

const RequestExample = ({
  requestTitle,
  requestContent,

  responseTitle,
  responseContent,
}) => (
  <>
    {requestContent && (
      <>
        <Response
          title={requestTitle}
        >
          {requestContent}
        </Response>
        <br />
      </>
    )}
    <Response
      title={responseTitle}
    >
      <span className="col-12 text-grey">{'{'}</span>
      {responseContent}
      <span className="col-12 text-grey">{'}'}</span>
    </Response>
  </>
);

export default RequestExample;
