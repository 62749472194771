import { Button, Form, Modal } from 'react-bootstrap';

const EditClientModal = ({
  show,
  handleClose,
  clientName,
  onClientNameChange,
  handleSelectedClientSave,
}) => (
  <Modal show={show} onHide={() => handleClose()}>
    <Modal.Header closeButton>
      <Modal.Title>
        Edit
        {' '}
        {clientName}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group className="form-group">
        <Form.Label htmlFor="inputFullName">Client Name</Form.Label>
        <Form.Control
          className="form-control form-control-sm"
          type="text"
          name="clientName"
          value={clientName}
          onChange={onClientNameChange}
        />
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="dark-blue" onClick={handleSelectedClientSave}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
);

export default EditClientModal;
