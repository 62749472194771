/* eslint-disable react/no-unstable-nested-components */
import { Container } from 'react-bootstrap';
import Loader from '../Loader/Loader';
import { auth } from '../../providers/FirebaseProvider/firebase';
import { isLoadingSignal, isLoggedInSignal, loadingSignal } from '../../libs/signals';
import NavBar from '../NavBar/NavBar';

const AppContainer = ({ children, isLoggedIn, loadingMessage }) => {
  const handleLogout = async () => {
    isLoggedInSignal.value = false;
    window.localStorage.setItem('loggedIn', false);
    await auth.signOut();
    window.location.href = '/login';
  };
  if (!isLoggedIn) {
    isLoadingSignal.value = false;
  }
  return (
  // SIGNALS LOADING FOR LOGGED IN USERS
    isLoadingSignal?.value ? (
      <Container fluid className="m-0 p-0 vh-100">
        {isLoggedIn && (
          <NavBar handleLogout={handleLogout} />
        )}
        <Container fluid className="min-vh-100 p-0 w-100 d-flex justify-content-center align-items-center flex-grow-1">
          <Loader message={loadingSignal?.value?.loading?.loadingMessage || loadingMessage} className="text-center" />
        </Container>
      </Container>
    ) : (
      <Container fluid className={`m-0 p-0 min-vh-100 ${isLoggedIn ? 'bg-light' : 'bg-light-grey'}`}>
        {isLoggedIn && (
          <NavBar handleLogout={handleLogout} />
        )}
        {children}
      </Container>
    )
  );
};
export default AppContainer;
