import { Button, Form, Modal } from 'react-bootstrap';
import validateEmail from '../../../utils/validateEmail';

const AddNewClientModal = ({
  show,
  handleClose,
  newClient,
  onNewClientChange,
  createNewClient,
}) => (
  <Modal show={show} onHide={() => handleClose()}>
    <Modal.Header closeButton>
      <Modal.Title>New Client</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group className="form-group">

        <Form.Label htmlFor="inputFullName" className="mb-16"><h5 className="mb-0">Client Name</h5></Form.Label>
        <Form.Control
          className="form-control form-control-sm mb-16"
          type="text"
          name="clientName"
          value={newClient?.clientData?.clientName}
          onChange={onNewClientChange}
        />
        <h5>Administrator Invite</h5>
        <Form.Label htmlFor="inputEmail">
          Email
          {!validateEmail(newClient?.clientData?.email) && (
            <small className="text-danger fw-700 ps-8">Invalid email</small>
          )}
        </Form.Label>
        <Form.Control
          className="form-control form-control-sm"
          type="text"
          name="email"
          value={newClient?.clientData?.email}
          onChange={onNewClientChange}
        />
        <Form.Label htmlFor="inputFullName" className="mt-16">Full Name</Form.Label>
        <Form.Control
          className="form-control form-control-sm"
          type="text"
          name="fullName"
          value={newClient?.clientData?.fullName}
          onChange={onNewClientChange}
        />
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="dark-blue"
        onClick={createNewClient}
        disabled={
          !newClient?.clientData?.clientName ||
          !validateEmail(newClient?.clientData?.email) ||
          !newClient?.clientData?.fullName
        }
      >
        Send Invite
      </Button>
    </Modal.Footer>
  </Modal>
);

export default AddNewClientModal;
