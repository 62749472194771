import { getConfig } from '../config/config';

const REACT_APP_API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');
const token = 'FYCADMINS';

export const apiFetch = async ({ path, body = undefined, method = 'GET' }) => {
  const methodToUse = method || (body ? 'POST' : 'GET');
  const res = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: methodToUse,
    body,
    headers: {
      'x-api-key': token,
      'Content-Length': body ? body.length.toString() : undefined,
      'Content-Type': body ? 'application/json' : undefined,
    },
  });
  const result = await res.json();
  return result;
};

export const apiFetchUnAuthenticated = async ({
  path,
  body = undefined,
  method = 'GET',
}) => {
  const methodToUse = method || (body ? 'POST' : 'GET');
  const requestOptions = {
    method: methodToUse,
    headers: {
      'Content-Length': body ? body.length.toString() : undefined,
      'Content-Type': body ? 'application/json' : undefined,
    },
  };
  if (body) {
    requestOptions.body = body;
  }

  const res = await fetch(REACT_APP_API_ENDPOINT + path, requestOptions);

  if (res.status > 399 && res.status < 500) {
    const errorRes = await res.json();
    const errorObj = {
      message: errorRes.message,
    };
    throw errorObj;
  }

  const result = await res.json();
  return result;
};

const get = async ({ path, options }) => {
  const queryString = new URLSearchParams({ options: JSON.stringify(options) }).toString();
  const pathString = options ? `${path}?${queryString}` : path;

  const { data } = await apiFetch({ path: pathString });
  return data;
};

const post = async ({ path, body }) => {
  const { data } = await apiFetch({ path, body: JSON.stringify(body), method: 'POST' });
  return data;
};

const patch = async ({ path, body }) => {
  const { data } = await apiFetch({ path, body: JSON.stringify(body), method: 'PATCH' });
  return data;
};

const deleteFunc = async ({ path }) => {
  const { data } = await apiFetch({ path, method: 'DELETE' });
  return data;
};

export default {
  get,
  post,
  patch,
  delete: deleteFunc,
};
