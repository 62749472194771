const { HttpMethod } = require('../../../../../../../constants');

const version = 'v1';
const baseUrl = 'filter-options';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'filter_object',
  },
  get: {
    id: 'filter_retrieval',
  },
};

const model = {
  title: 'The Filter Options',
  header: 'Filter Options',
  object: endpointIds.object.id,
  link: `/docs/api/v2#${endpointIds.object.id}`,
};

const attributes = {
  data: { type: 'array of strings', description: 'An array of options for the specified filter.' },
};

const endpoints =
{
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}?filter=`,
    example: `/${baseUrl}?filter=status`,
    title: 'Retrieve filter options',
    description: 'Retrieves the options for the facetFilters',
    returnContent: 'Returns an array of strings in a data property',
    queryParameters: {
      filter: {
        type: 'required',
        description: 'The name of the filter you would like to retrieve options for. Possible value is locations.',
      },
    },
  },
};

export {
  model,
  attributes,
  endpoints,
};
