import SummarySection from '../Shared/Sections/SummarySection';

const APIReference = () => {
  const endpoints = {
    base: {
      link: '/docs/api/v2#api_reference',
      endpoint: 'https://us-central1-opengrants-api-portal.cloudfunctions.net/api/v2',
    },
  };

  const summary = (
    <>
      <p>
        This document provides all endpoints available in the OpenGrants API as well
        as example requests in curl and example responses from the OpenGrants API.
      </p>
      <p>
        The OpenGrants API uses REST. It will return JSON-encoded responses
        if the request succeeded. All requests must be authenticated by adding
        a X-Api-Key request header and setting it to one of your API keys. You
        can view and manage your API keys in the
        <span> </span>
        <a href="https://developer.opengrants.io/keys" target="blank">
          OpenGrants Developer Portal
        </a>
        <span>.</span>
      </p>
    </>
  );

  return (
    <div id="api_reference">
      <section id="api_reference">
        <SummarySection
          header="API Reference v2"
          summary={summary}
          endpoints={endpoints}
          title="BASE URL"
        />
      </section>
    </div>
  );
};

export default APIReference;
