/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Form, Row, Button, Dropdown } from 'react-bootstrap';
import api from '../../../api/api';
import { clientDataSignal, isLoadingSignal, isLoggedInSignal, userDataSignal } from '../../libs/signals';
import { useAlert } from '../../providers/AlertProvider/AlertProvider';
import { firestore } from '../../providers/FirebaseProvider/firebase';
import convertToDateTime from '../../utils/convertToDateTime';
import AddUserModal from './components/AddUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import EditUserModal from './components/EditUserModal';

const Users = () => {
  const setAlert = useAlert();
  const [searchValue, setSearchValue] = useState('');
  const [filterRole, setFilterRole] = useState(false);
  const [filterLastLogin, setFilterLastLogin] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [newMember, setNewMember] = useState({
    userData: {
      name: '',
      email: '',
      userType: '',
    },
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isNewUserOpen, setIsNewUserOpen] = useState(false);

  const fetchClientData = useCallback(async (role, onSearchValue) => {
    isLoggedInSignal.value = true;
    const fbClientRef = firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId);
    const clientSnap = await fbClientRef.get();
    const clientObj = clientSnap.data();
    const membersArray = [];
    if (clientObj?.clientData) {
      for (let i = 0; i < clientObj.clientData.members.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const data = await clientObj.clientData.members[i].userObj.get();
        const memberData = data.data();
        membersArray.push(memberData);
      }
      let filteredMembersArray = [];
      if (!role) {
        filteredMembersArray = membersArray;
      } else if (role === 'role') {
        filteredMembersArray = membersArray.sort((a, b) => ((a.userData.userType > b.userData.userType) ? 1 : ((b.userData.userType > a.userData.userType) ? -1 : 0)));
      } else if (role === 'lastLogin') {
        const nullResults = membersArray.filter(member => member.userData.lastSignInTime === null);
        const results = membersArray.filter(member => member.userData.lastSignInTime !== null);
        results.sort((a, b) => ((a.userData.lastSignInTime.seconds * 1000 < b.userData.lastSignInTime.seconds * 1000) ? 1 : ((b.userData.lastSignInTime.seconds * 1000 < a.userData.lastSignInTime.seconds * 1000) ? -1 : 0)));
        nullResults.forEach(obj => {
          results.push(obj);
        });
        filteredMembersArray = results;
      } else if (role === 'active') {
        filteredMembersArray = membersArray.sort((a, b) => ((a.userData.isActive < b.userData.isActive) ? 1 : ((b.userData.isActive < a.userData.isActive) ? -1 : 0)));
      }
      if (onSearchValue && onSearchValue !== '') {
        filteredMembersArray = membersArray.filter(member => member.userData.name.includes(onSearchValue) || member.userData.email.includes(onSearchValue));
      }
      const resultObj = {
        clientData: {
          name: clientSnap.data()?.clientData.name,
          members: filteredMembersArray,
        },
      };
      clientDataSignal.value = resultObj;
      isLoggedInSignal.value = false;
    }
  }, []);

  useEffect(() => {
    fetchClientData();
  }, []);

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    fetchClientData(null, e.target.value);
  };

  const handleFilterClicked = async (type, originalValue) => {
    if (type === 'role' && !originalValue) {
      setFilterRole(true);
      setFilterLastLogin(false);
      setFilterActive(false);
    } else if (type === 'lastLogin' && !originalValue) {
      setFilterRole(false);
      setFilterLastLogin(true);
      setFilterActive(false);
    } else if (type === 'active' && !originalValue) {
      setFilterRole(false);
      setFilterLastLogin(false);
      setFilterActive(true);
    } else {
      setFilterRole(false);
      setFilterLastLogin(false);
      setFilterActive(false);
    }
    await fetchClientData(type);
  };

  // RESEND INVITE FUNCTIONALITY
  const resendInvite = (value) => {
    try {
      const postBody = {
        email: value.userData.email,
        name: value.userData.name,
      };
      api.post({ path: '/account/invite', body: postBody });
      setAlert({
        message: 'Invite Sent!',
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: 'Invite failed to send!',
        variant: 'danger',
      });
    }
  };

  // ON NEW MEMBER FUNC
  const handleNewUserClicked = () => {
    setIsNewUserOpen(!isNewUserOpen);
  };
  const onNewMemberChange = (e) => {
    setNewMember({
      userData: {
        ...newMember?.userData,
        [e.target.name]: e.target.value,
      },
    });
  };
  const onNewMemberSave = async () => {
    try {
      isLoadingSignal.value = true;
      firestore.collection('users').doc(newMember?.userData?.email).get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          // eslint-disable-next-line no-alert
          alert('USER ALREADY EXISTS');
          isLoadingSignal.value = false;
        } else {
          firestore.collection('users').doc(newMember?.userData?.email).set({
            userData: {
              ...newMember?.userData,
              isActive: false,
              clientId: userDataSignal?.value?.userData?.clientId,
              uid: null,
              lastSignInTime: null,
              creationTime: null,
            },
          });
          const fbClientRef = firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId);
          const clientSnap = await fbClientRef.get();
          const clientObj = clientSnap.data();
          const membersArray = clientObj?.clientData?.members;
          membersArray.push({
            userObj: firestore.collection('users').doc(newMember?.userData?.email),
          });

          firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId).update({
            clientData: {
              ...clientObj?.clientData,
              name: clientObj?.clientData?.name,
              members: membersArray,
            },
          });
          const postBody = {
            email: newMember?.userData?.email,
            name: newMember?.userData?.name,
          };
          api.post({ path: '/account/invite', body: postBody });
          handleNewUserClicked();
          setNewMember({
            userData: {
              name: '',
              email: '',
              userType: '',
            },
          });
          await fetchClientData();
          setAlert({
            message: 'Invite successfully sent to the user',
            variant: 'success',
          });
        }
      });
    } catch (error) {
      console.error(error);
      isLoadingSignal.value = false;
    } finally {
      isLoadingSignal.value = false;
    }
  };

  // ON EDIT FUNC
  const handleEditClicked = (value) => {
    setSelectedMember(value || null);
    setIsEditOpen(!isEditOpen);
  };
  const onSelectedMemberChange = (e) => {
    setSelectedMember({
      userData: {
        ...selectedMember?.userData,
        [e.target.name]: e.target.value,
      },
    });
  };
  const handleSelectedMemberSave = async () => {
    try {
      isLoadingSignal.value = true;
      firestore.collection('users').doc(selectedMember?.userData?.email).update({
        userData: {
          ...selectedMember?.userData,
        },
      });
      handleEditClicked();
      await fetchClientData();
      setAlert({
        message: 'User was successfully updated',
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      isLoadingSignal.value = false;
    } finally {
      isLoadingSignal.value = false;
    }
  };

  // ON MEMBER DELETE FUNC
  const handleDeleteClicked = (value) => {
    setSelectedMember(value || null);
    setIsDeleteOpen(!isDeleteOpen);
  };
  const handleDeleteSubmitted = async () => {
    try {
      isLoadingSignal.value = true;
      const usersPositionInArray = clientDataSignal?.value?.clientData?.members.findIndex(
        member => member.userData.email === selectedMember?.userData?.email,
      );
      const fbClientRef = firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId);
      const clientSnap = await fbClientRef.get();
      const clientObj = clientSnap.data();
      const membersArray = clientObj?.clientData?.members;
      membersArray.splice(usersPositionInArray, 1);
      firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId).update({
        clientData: {
          ...clientObj?.clientData,
          name: clientObj?.clientData?.name,
          members: membersArray,
        },
      });
      firestore.collection('users').doc(selectedMember?.userData?.email).delete();
      handleDeleteClicked();
      await fetchClientData();
      setAlert({
        message: 'User was successfully deleted',
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      isLoadingSignal.value = false;
    } finally {
      isLoadingSignal.value = false;
    }
  };

  return (
    <Container fluid className="px-48">
      <h1 className="mt-32 fw-700">
        Team
        {' '}
        &quot;
        {clientDataSignal.value?.clientData?.name}
        &quot;
      </h1>
      <Row className="m-0 mt-32">
        <Col sm={4} className="ps-0 p-0">
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Filter by name or email..."
                value={searchValue}
                onChange={onSearchChange}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col sm={{ offset: 5, span: 1 }} className="d-flex align-content-center ps-0">
          <Dropdown
            align="end"
          >
            <Dropdown.Toggle
              variant="outline-dark"
              id="dropdown-basic"
              className="p-0 px-8 py-0 my-8"
            >
              <FontAwesomeIcon icon={faFilter} className="me-8" />
              Sort
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              <Dropdown.Item
                className={`${filterRole && 'fw-800 text-dark-blue'}`}
                onClick={() => handleFilterClicked('role', filterRole)}
              >
                ROLES
              </Dropdown.Item>
              <Dropdown.Item
                className={`${filterLastLogin && 'fw-800 text-dark-blue'}`}
                onClick={() => handleFilterClicked('lastLogin', filterLastLogin)}
              >
                LAST LOGIN
              </Dropdown.Item>
              <Dropdown.Item
                className={`${filterActive && 'fw-800 text-dark-blue'}`}
                onClick={() => handleFilterClicked('active', filterActive)}
              >
                ACTIVE
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col sm={2} className="d-flex align-content-center justify-content-end">
          {userDataSignal?.value?.userData?.userType === 'Administrator' && (
            <Button
              className="p-0 px-8 py-0 my-8"
              variant="dark-blue"
              onClick={() => handleNewUserClicked()}
            >
              + New Member
            </Button>
          )}
        </Col>
      </Row>
      <Row className="py-8 border-bottom mx-0" />
      <Row className="mt-8 mb-16">
        <Col sm={3} className="fw-700">
          MEMBERS
        </Col>
        <Col sm={5} className="fw-700">
          ROLES
        </Col>
        <Col sm={2} className="fw-700">
          LAST LOGIN
        </Col>
      </Row>
      {clientDataSignal.value?.clientData?.members
      && clientDataSignal.value?.clientData?.members.map((member) => (
        <div className="border-bottom" key={member?.userData?.email}>
          <Row className="mt-8">
            <Col sm={3} className="fw-700">
              {member?.userData?.name}
            </Col>
            <Col sm={5}>
              {member?.userData?.userType}
            </Col>
            <Col sm={3}>
              {member?.userData?.lastSignInTime ? convertToDateTime(member?.userData?.lastSignInTime) : 'User has not logged in'}
            </Col>
            {userDataSignal?.value?.userData?.userType === 'Administrator' && (
              <Col sm={1} className="d-flex justify-contnet-end">
                <Dropdown
                  align="end"
                >
                  <Dropdown.Toggle
                    variant="outline-none"
                    id="dropdown-basic"
                    className="ms-auto p-0 mx-auto w-100"
                  >
                    ...
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-0">
                    {!member?.userData?.lastSignInTime && (
                      <Dropdown.Item
                        onClick={() => resendInvite(member)}
                      >
                        RESEND INVTIE
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => handleEditClicked(member)}
                    >
                      EDIT
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDeleteClicked(member)}
                    >
                      DELETE
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={3}>
              {member?.userData?.readableEmail || member?.userData?.email}
            </Col>
          </Row>
        </div>
      ))}
      {clientDataSignal.value?.clientData?.members && (
        <div className="mt-8">
          {clientDataSignal.value?.clientData?.members.length}
          {' '}
          members
        </div>
      )}
      <AddUserModal
        show={isNewUserOpen}
        handleClose={handleNewUserClicked}
        newMember={newMember}
        onNewMemberChange={onNewMemberChange}
        onNewMemberSave={onNewMemberSave}
      />
      <EditUserModal
        show={isEditOpen}
        handleClose={handleEditClicked}
        selectedMember={selectedMember}
        onSelectedMemberChange={onSelectedMemberChange}
        handleSelectedMemberSave={handleSelectedMemberSave}
      />
      <DeleteUserModal
        show={isDeleteOpen}
        selectedMember={selectedMember}
        handleClose={handleDeleteClicked}
        handleDeleteSubmitted={handleDeleteSubmitted}
      />
    </Container>
  );
};

export default Users;
