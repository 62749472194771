const model = {
  header: 'Pagination',
  title: 'The Pagination Object',
  object: 'pagination_object',
  link: '/docs/api/v1#pagination_object',
};

const attributes = {
  data: { type: 'array of objects', description: 'An array of objects matching the search criteria.' },
  totalResults: { type: 'int', description: 'The total number of objects found.' },
  page: { type: 'int', description: 'The current pagination number.' },
  totalPages: { type: 'int', description: 'The total number of pages to query to get all results.' },
};

export {
  model,
  attributes,
};
