import { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import SearchBar from './components/searchBar';
import LogsView from './components/logsView';
import { firestore } from '../../providers/FirebaseProvider/firebase';
import convertToDateTime from '../../utils/convertToDateTime';
import { isLoadingSignal, userDataSignal } from '../../libs/signals';

const Logs = () => {
  const [datesArray, setDatesArray] = useState(null);
  const [activeObj, setActiveObj] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const [logs, setLogs] = useState([]);

  const fetchLogsData = useCallback(async () => {
    const ref = await firestore
      .collection('stats')
      .doc(userDataSignal?.value?.userData?.clientId)
      .collection('logs')
      .orderBy('createdAt', 'desc')
      .get();

    const logsData = ref.docs.map((doc) => doc.data());
    setLogs(logsData);
    const dates = [];
    logsData.forEach((obj) => {
      if (!dates.includes(convertToDateTime(obj?.createdAt, 'dateOnly'))) {
        dates.push(convertToDateTime(obj?.createdAt, 'dateOnly'));
      }
    });
    setDatesArray(dates);
  });

  useEffect(() => {
    isLoadingSignal.value = true;
    fetchLogsData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tab = urlParams.get('tab');
    if (tab === 'failed') {
      setActiveTab('failed');
    }
    isLoadingSignal.value = false;
  }, []);

  const handleSetId = (value) => {
    setActiveObj(value);
  };

  if (datesArray && logs) {
    return (
      <Container fluid>
        <Row className="m-16">
          <Col sm={2} className="mt-32">
            <h2 className="text-center fw-700">Developers</h2>
            <Row className="mt-16">
              <Col sm={4} className="pe-0">
                <Card bg="light-grey h-100 ms-auto border-0" style={{ width: '8px' }}>
                  <Card bg="dark-blue" className="py-8 mt-auto border-0" style={{ width: '8px' }} />
                </Card>
              </Col>
              <Col sm={7}>
                <Link className="text-decoration-none text-dark" to="/dashboard"><h5 className="mb-24">Overview</h5></Link>
                <Link className="text-decoration-none text-dark" to="/keys"><h4 className="mb-24">API keys</h4></Link>
                <Link className="text-decoration-none text-dark-blue" to="/logs"><h5 className="mb-0 fw-700">Logs</h5></Link>
              </Col>
            </Row>
          </Col>
          <Col sm={10}>
            <h1 className="mb-0 fw-700 me-auto">
              Logs
            </h1>
            <Container fluid className="p-0">
              <Tabs
                defaultActiveKey={activeTab}
                className="p-0 mt-8"
              >
                <Tab
                  className="fw-800"
                  eventKey="all"
                  title="All"
                >
                  <LogsView
                    tempData={logs}
                    datesArray={datesArray}
                    handleSetId={handleSetId}
                    activeObj={activeObj}
                    currentTab="all"
                  />
                </Tab>
                <Tab
                  eventKey="succeeded"
                  title="Succeeded"
                >
                  <LogsView
                    tempData={logs}
                    datesArray={datesArray}
                    handleSetId={handleSetId}
                    activeObj={activeObj}
                    currentTab="succeeded"
                  />
                </Tab>
                <Tab
                  eventKey="failed"
                  title="Failed"
                >
                  <LogsView
                    tempData={logs}
                    datesArray={datesArray}
                    handleSetId={handleSetId}
                    activeObj={activeObj}
                    currentTab="failed"
                  />
                </Tab>
              </Tabs>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
  return null;
};

export default Logs;
