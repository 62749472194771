import Attributes from './Attributes';

const Parameters = ({ queryParameters, bodyParameters, returnContent }) => (
  <div>
    <div>
      <h4>Query Parameters</h4>
      {!queryParameters ?
        <p>No query parameters</p> :
        (
          <Attributes
            model={queryParameters}
          />
        )}
    </div>
    <hr />
    <div className="pt-24">
      <h4>Body Parameters</h4>
      {!bodyParameters ?
        <p>No body parameters</p> :
        (
          <Attributes
            model={bodyParameters}
          />
        )}
    </div>
    <hr />
    <div className="pt-24">
      <h4>Returns</h4>
      {returnContent}
    </div>
  </div>
);

export default Parameters;
