/* eslint-disable react/no-unstable-nested-components */
import { Navbar, Offcanvas, Container, Nav, Image } from 'react-bootstrap';
import NavLogo from '../../../images/OG-Nav-Logo.svg';
import Dashboard from '../../../images/Dashboard.svg';
import ogLogs from '../../../images/OG-Logs.svg';
import ogUsers from '../../../images/OG-Users.svg';
import ogBilling from '../../../images/OG-Billing.svg';
import ogOpen from '../../../images/OG-Open.svg';
import Settings from '../../../images/OG-Settings.svg';
import signOut from '../../../images/OG-SignOut.svg';
import folder from '../../../images/folder.svg';
import ogLogo from '../../../images/logo.png';
import { userDataSignal } from '../../libs/signals';

const NavBar = ({ handleLogout, isOpenGrants }) => (
  <Navbar key={false} bg="grayscale" expand={false} className="p-0 py-8">
    <Container fluid>
      <Navbar.Brand>
        <Image src={NavLogo} alt="NavBar controls" className="d-inline-block align-top ms-16" height="40px" />
        <h1 className="fw-700 d-inline-block h-100 ms-8 mb-0 pb-0">Developers Portal</h1>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} className="border-0">
        <Image src={Settings} alt="NavBar controls" />
      </Navbar.Toggle>
      <Navbar.Offcanvas
        id={`offcanvasNavbar-expand-${false}`}
        aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
        placement="end"
      >
        <Offcanvas.Header closeButton className="py-0 me-8">
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`} className="justify-content-end">
            <Image className="m-8 d-inline-block align-top ps-56" src={NavLogo} alt="NavBar controls" height="40px" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link href="/dashboard" className="d-flex">
              <Image src={Dashboard} width="25px" height="25px" className="my-auto" />
              <h3 className="fw-600 text-dark mb-0 ms-16">
                Dashboard
                {' '}
                {isOpenGrants}
              </h3>
            </Nav.Link>
            <Nav.Link href="/logs" className="mt-16 d-flex">
              <Image src={ogLogs} width="25px" height="25px" className="my-auto" />
              <h3 className="fw-600 text-dark mb-0 ms-16">Logs</h3>
            </Nav.Link>
            <Nav.Link href="/users" className="mt-16 d-flex">
              <Image src={ogUsers} width="25px" height="25px" className="my-auto" />
              <h3 className="fw-600 text-dark mb-0 ms-16">Users</h3>
            </Nav.Link>
            <Nav.Link href="/docs/api/v2" target="blank" className="mt-16 d-flex">
              <Image src={folder} width="25px" height="25px" className="my-auto" />
              <h3 className="fw-600 text-dark mb-0 ms-16 me-8">Docs</h3>
              <Image src={ogOpen} width="25px" height="25px" />
            </Nav.Link>
            <Nav.Link onClick={() => window.open('https://billing.stripe.com/p/login/aEUaG61ZubPSdr2aEE')} className="mt-16 d-flex">
              <Image src={ogBilling} width="25px" height="25px" className="my-auto" />
              <h3 className="fw-600 text-dark mb-0 ms-16 me-8">Billing</h3>
              <Image src={ogOpen} width="25px" height="25px" />
            </Nav.Link>
            {userDataSignal?.value?.userData?.clientId === 'v3C5vOJ50dE0dT79TPFk' && (
              <Nav.Link href="/clients" className="mt-16 d-flex">
                <Image src={ogLogo} width="25px" height="25px" className="my-auto" />
                <h3 className="fw-600 text-dark mb-0 ms-16">Clients</h3>
              </Nav.Link>
            )}
            <Nav.Link onClick={handleLogout} className="mt-16 d-flex">
              <Image src={signOut} width="25px" height="25px" className="my-auto" />
              <h3 className="fw-600 text-dark mb-0 ms-16">Sign Out</h3>
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>
);
export default NavBar;
