import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SideNavigation from './Components/SideNavigation';
import APIReference from './Components/APIReference';
import NotificationBanner from '../../../Banners/NotificationBanner';
import * as grantObject from './Shared/Objects/GrantObject';
import * as funderObject from './Shared/Objects/FunderObject';
import * as vendorObject from './Shared/Objects/VendorObject';
import * as matchObject from './Shared/Objects/MatchObject';
import * as paginationObject from './Shared/Objects/PaginationObject';
import * as filterOptionObject from './Shared/Objects/FilterOptionObject';
import ApiSection from './Shared/Sections/ApiSection';
import { apiFetchUnAuthenticated } from '../../../../../api/api';
import { BannerTypes } from '../../../../../constants/Banners';

const Doc = () => {
  const [objects, setObjects] = useState([
    { object: grantObject, examples: {} },
    { object: funderObject, examples: {} },
    { object: matchObject, examples: {} },
    { object: vendorObject, examples: {} },
    { object: paginationObject, examples: {} },
    { object: filterOptionObject, examples: {} },
  ]);
  const version = 'v1';

  const init = async () => {
    // get grant examples

    const [
      getGrantExamples,
      getAllGrantExamples,
      searchGrantExamples,
      getFunderExamples,
      getFilterOptionExamples,
      getVenderExamples,
      searchVendorExamples,
    ] = await Promise.all([
      apiFetchUnAuthenticated({ path: `/${version}/examples${grantObject.endpoints.get.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${grantObject.endpoints.getAll.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${grantObject.endpoints.search.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${funderObject.endpoints.get.example}` }),
      apiFetchUnAuthenticated({ path: `/v2/examples${filterOptionObject.endpoints.get.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${vendorObject.endpoints.get.example}` }),
      apiFetchUnAuthenticated({ path: `/${version}/examples${vendorObject.endpoints.search.example}` }),
    ]);

    let newObjects = [
      {
        object: grantObject,
        examples: {
          object: getGrantExamples.data,
          get: getGrantExamples,
          getAll: getAllGrantExamples,
          search: searchGrantExamples,
        },
      },
      {
        object: funderObject,
        examples: {
          object: getFunderExamples.data,
          get: getFunderExamples,
        },
      },
      {
        object: vendorObject,
        examples: {
          object: getVenderExamples.data,
          get: getVenderExamples,
          search: searchVendorExamples,
        },
      },
      {
        object: matchObject,
        examples: {},
      },
      {
        object: paginationObject,
        examples: {
          object: searchGrantExamples.data,
        },
      },
      {
        object: filterOptionObject,
        examples: {
          object: getFilterOptionExamples,
          get: getFilterOptionExamples,
        },
      },
    ];

    setObjects(newObjects);

    const getAllMatchExamples = await apiFetchUnAuthenticated({ path: `/${version}/examples${matchObject.endpoints.getAll.example}` });

    newObjects = [
      {
        object: grantObject,
        examples: {
          object: getGrantExamples.data,
          get: getGrantExamples,
          getAll: getAllGrantExamples,
          search: searchGrantExamples,
        },
      },
      {
        object: funderObject,
        examples: {
          object: getFunderExamples.data,
          get: getFunderExamples,
        },
      },
      {
        object: vendorObject,
        examples: {
          object: getVenderExamples.data,
          get: getVenderExamples,
          search: searchVendorExamples,
        },
      },
      {
        object: matchObject,
        examples: {
          object: getAllMatchExamples,
          getAll: getAllMatchExamples,
          generate: getAllMatchExamples,
        },
      },
      {
        object: paginationObject,
        examples: {
          object: searchGrantExamples.data,
        },
      },
      {
        object: filterOptionObject,
        examples: {
          object: getFilterOptionExamples,
          get: getFilterOptionExamples,
        },
      },
    ];

    setObjects(newObjects);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Row className="vw-100 vh-100 bg-white">
      <Col sm={3} className="p-48 h-100 overflow-scroll">
        <h2>
          OpenGrants API
          <span> </span>
          {version}
        </h2>
        <SideNavigation objects={objects} />
      </Col>
      <Col sm={9} className="p-48 h-100 overflow-scroll api-docs">
        <NotificationBanner
          type={BannerTypes.NOTIFICATION}
          title="Notification Alert"
          text={
            (
              <span>
                This version, formerly hosted at
                <span> </span>
                <a href="https://app.swaggerhub.com/apis-docs/OpenGrants/og-api-prod-dev-portal/1.0.0" target="blank">Swagger Hub</a>
                , is deprecated. You can find version 2 of the OpenGrants API doc
                <span> </span>
                <a href="/docs/api/v2">here.</a>
              </span>
            )
          }
        />
        <APIReference version={version} />
        <hr />
        {
          objects.map(({ object, examples }) => <ApiSection object={object} examples={examples} />)
        }
      </Col>
    </Row>
  );
};

export default Doc;
